.add-credits {
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  * {
    flex: 1;
  }
  &.sidebar {
    max-width: initial;
    .add-credits-input {
      flex: 3;
    }
    .submit-button {
      flex: 1;
    }
  }
  .extra-fields {
    flex: 100%;
    margin-top: 10px;
  }
}