.alert-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-size: 1.5em;
  h4 {
    margin-bottom: 0px;
  }
}
